import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import LoadingPage from '../LoadingPage';
import StartPageCampaign from './Content/StartPageCampaign';
import StartPageCategories from './Content/StartPageCategories';
import StartPageHero from './Content/StartPageHero';
import StartPageHTMLContent from './Content/StartPageHTMLContent';
import StartPageProductGrid from './Content/StartPageProductGrid';
import startPageQuery from './StartPageQuery.gql';
import { StartPageCategoryItem } from './Content/StartPageCategories';
import { allContentComponents } from '../ContentComponents/ContentComponents';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useLocation } from 'react-router';

// const startPageComponents = {
//   HERO: StartPageHero,
//   HTML: StartPageHTMLContent,
//   CATEGORIES: StartPageCategories,
//   CAMPAIGN: StartPageCampaign,
//   PRODUCTGRID: StartPageProductGrid,
//   CATEGORYITEM: StartPageCategoryItem
// };

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StartPage = ({ startPageId }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const location = useLocation();

  console.log('location', location);

  const findChannelStartPageId = () => {
    switch (selectedChannel?.id) {
      //Cykel&Fritid
      case 1:
        return 77;
      //OnlineCykel
      case 2:
        return 57;
      //XLCykel
      case 3:
        return 67;
      //Sandnas
      case 4:
        return 44;
      default:
        return 77;
    }
  };

  const channelStartPageId = location?.pathname.includes('preview')
    ? startPageId
    : findChannelStartPageId();

  console.log('channelStartPageId', channelStartPageId);

  const result = useQuery(startPageQuery, {
    variables: { startPageId: channelStartPageId }
  });
  const { loading } = result;

  if (loading || !result?.data) return <LoadingPage />;

  return (
    <StartPageWrapper>
      <StartPageRenderer
        result={result}
        startPageComponents={allContentComponents}
        LoadingPage={LoadingPage}
      />
    </StartPageWrapper>
  );
};

export default StartPage;
